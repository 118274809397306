import "@babel/polyfill";
import lozad from 'lozad';
import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import Accordion from "accordion/src/accordion.mjs";
import mixitup from "mixitup";
import mixitupMultiFilter from "./mixitup-multifilter";

import '../css/app.pcss';

import "../img/footer-top.svg";
import "../img/bg-bucket.svg";

import FooterLogo from "../img/footer-logo.svg";

import Logo from "../img/logo.svg";
import Paypal from "../img/paypal.svg";
import Visa from "../img/visa.svg";
import Mastercard from "../img/mastercard.svg";

import NextArrow from "../img/slider-arrow-next.svg";
import PrevArrow from "../img/slider-arrow-prev.svg";

// App main
const main = async () => {

    const animate = await import(/* webpackChunkName: "animate" */ 'animate.css/animate.css');
    const fontawesome = await import(/* webpackChunkName: "fontawesome" */ 'font-awesome/css/font-awesome.min.css');
    const glideCss = await import(/* webpackChunkName: "glide" */ '@glidejs/glide/dist/css/glide.core.css');
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');

    const observer = lozad();
    observer.observe();

    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        components: {
            'stores-grid-map': () => import(/* webpackChunkName: "stores-grid-map" */ '../vue/StoresGridMap.vue')
        },
        data: {
            openMobile: false,
            openFilter: false,
            password: '',
            passwordFieldType: 'password',
            showNewAddress: 1,
            showNotice: true,
            showError: true,
            showCartError: true,
            showProfileDropdown: false,
        },
        methods: {
            toggleMobile(e) {
                e.preventDefault();
                this.openMobile = !this.openMobile;
                this.openMobile ? bodyScrollLock.disableBodyScroll(this.$refs.navMobile) : bodyScrollLock.enableBodyScroll(this.$refs.navMobile);
            },
            toggleFilter(e) {
                e.preventDefault();
                this.openFilter = !this.openFilter;
            },
            switchVisibility(e) {
                e.preventDefault();
                this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
            },
            cartUpdate(e) {
                e.target.form.submit();
            },
            toggleNotice(e) {
                e.preventDefault();
                this.showNotice = !this.showNotice;
            },
            toggleError(e) {
                e.preventDefault();
                this.showError = !this.showError;
            },
            toggleCartError(e) {
                e.preventDefault();
                this.showCartError = !this.showCartError;
            },
            toggleProfileDropdown(e) {
                e.preventDefault();
                this.showProfileDropdown = !this.showProfileDropdown;
            }
        },
        mounted() {},
    });

    // Add header logo to img container
    var headerLogoContainer = document.getElementById('site__logo');
    if (headerLogoContainer) headerLogoContainer.src = Logo;

    // Add footer logo to img container
    var footerLogoContainer = document.getElementById('footer__logo');
    if (footerLogoContainer) footerLogoContainer.src = FooterLogo;

    // Add footer logo to img container
    var paypalLogoContainer = document.getElementById('footer__paypal');
    if (paypalLogoContainer) paypalLogoContainer.src = Paypal;

    // Add footer logo to img container
    var visaLogoContainer = document.getElementById('footer__visa');
    if (visaLogoContainer) visaLogoContainer.src = Visa;

    // Add footer logo to img container
    var mastercardLogoContainer = document.getElementById('footer__mastercard');
    if (mastercardLogoContainer) mastercardLogoContainer.src = Mastercard;

    var glideArrowImageNext = document.getElementById('glide__arrow__image--next');
    if(glideArrowImageNext) glideArrowImageNext.src = NextArrow;

    var glideArrowImagePrev = document.getElementById('glide__arrow__image--prev');
    if(glideArrowImagePrev) glideArrowImagePrev.src = PrevArrow;

};

// Execute async function
main().then( (value) => {

    document.body.classList.add('page--loaded');

    for(const el of document.querySelectorAll(".accordion")) {
		new Accordion(el, {
            onToggle: function(fold, isOpen) {
                var icon = fold.heading.querySelector('.fa');
                if(isOpen) {
                    icon.classList.remove('fa-chevron-down');
                    icon.classList.add('fa-chevron-up');
                } else {
                    icon.classList.remove('fa-chevron-up');
                    icon.classList.add('fa-chevron-down');
                }
            }
        });
    }

    for(const el of document.querySelectorAll(".glide")) {
        new Glide(el).mount({ Controls, Breakpoints, Swipe })
    }

    if(document.querySelectorAll(".list__results").length) {
        mixitup.use(mixitupMultiFilter);

        var Mixer = mixitup('.list__results', {
            selectors: {
                target: '.item'
            },
            multifilter: {
                enable: true // enable the multifilter extension for the mixer
            }
        });
    }
});
